<template>
<div class="step__body">
  <div class="row mb-3">
    <div v-for="color in regularColors" :key="color.id" class="col-6 option option-color option--horizontal">
      <ColorOption :option="color"/>
    </div>
  </div>
  <div v-if="specialColors.length > 0" class="option__title mb-3">Spezialfarben{{ step.label_special }} (+{{ step.price_special }}%)</div>
  <div v-if="specialColors.length > 0" class="row">
    <div v-for="color in specialColors" :key="color.id" class="col-6 option option-color option--horizontal">
      <ColorOption :option="color"/>
    </div>
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import ColorOption from './colorOption'
export default {
  components: {
    ColorOption
  },
  setup () {
    const store = useStore()
    const regularColors = computed(() => {
      const step = store.getters['configurator/currentStep']
      return step.colors.filter(c => !c.special && c.visible)
    })
    const specialColors = computed(() => {
      const step = store.getters['configurator/currentStep']
      return step.colors.filter(c => c.special && c.visible)
    })
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    return {
      step,
      regularColors,
      specialColors
    }
  }
}
</script>
